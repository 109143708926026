import React from 'react';
import { withTranslation } from 'react-i18next';
import BaseComponent from '../BaseComponent';
import withRoomDetails from '../../hoc/withRoomDetails';
import withRoomToken from '../../hoc/withRoomToken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { room$, appointmentId$, participantId$, conversationSid$, conversation$ } from '../../services/subjects';
import roomService from '../../services/roomService';
import { conversationService } from '../../services/conversationService';
import { connect, createLocalTracks } from 'twilio-video';
import { isAudioAppointment, isMobile } from '../../common/utility';
import { requestPermissions, isMediaAccessGranted } from '../../common/media-access-utility';
import { getLangCode } from '../../common/utility';
import {
    appointmentStatuses,
    appointmentStatusMap,
    internalRoomStatusesMap,
    participantVisitEvent,
} from '../../common/constants';
import RoomBody from './RoomBody';
import { dataTrack } from '../../hooks/useDataTrack';
import { Modal } from 'react-bootstrap';
import styles from './VirtualRoom.module.scss';
import withRouter from '../../hoc/withRouter';

const isMobileAgent = isMobile();

const generateUniqueId = () => {
    let first = (Math.random() * 46656) | 0;
    let second = (Math.random() * 46656) | 0;
    first = ('000' + first.toString(36)).slice(-3);
    second = ('000' + first.toString(36)).slice(-3);
    return first + second;
};

class VirtualRoom extends BaseComponent {
    static displayName = VirtualRoom.name;

    constructor(props) {
        super(props);
        this.state = {
            room: null,
            roomInternalStatus: null,
            memberIsConnected: false,
            shouldShowModal: false,
            modalText: '',
            conversationsToken: null,
            participantName: null,
            participantIdentity: null,
            conversation: null,
            showCallEnded: true,
        };
        this.subscriptions = [];
        const { t } = this.props;
        this.t = t;
    }

    connectToRoom = async (token, roomName, appointment) => {
        try {
            this.setState({
                shouldShowModal: true,
                modalText: this.t('requestMicrophoneAccess'),
            });
            const tracks = await createLocalTracks({
                audio: true,
                video: isAudioAppointment(appointment) ? undefined : { facingMode: 'user' },
            });
            this.setState({
                shouldShowModal: false,
                modalText: '',
            });
            const room = await connect(token, {
                name: roomName,
                tracks: [dataTrack, ...tracks],
                preferredVideoCodecs: ['H264'],
                dominantSpeaker: true,
                video: this.getVideoSettings(),
                maxAudioBitrate: 16000,
                networkQuality: {
                    local: 3,
                    remote: 3,
                },
            });
            room$.next({ room, roomInternalStatus: internalRoomStatusesMap.CONNECTED });
            this.setState({ memberIsConnected: true });
        } catch (e) {
            /** todo implement handler */
            if (e.name == 'NotAllowedError') {
                this.setState({
                    shouldShowModal: false,
                    modalText: this.t(''),
                });
                this.setState({ permissionDenied: true });
                this.subscriptions.push(
                    roomService
                        .trackEvent$(
                            appointment.id,
                            this.props.router.params.participantId,
                            participantVisitEvent.deviceAccessDenied,
                        )
                        .subscribe(),
                );
            }
            console.log(e);
        }
    };

    getVideoSettings = () => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
            return { height: 480, frameRate: 24, width: 640 };
        } else {
            return { height: 720, frameRate: 24, width: 1280 };
        }
    };

    componentDidMount() {
        if (!isMediaAccessGranted()) {
            requestPermissions();
        }

        const { token, appointmentDetails } = this.props;
        const { appointmentId, participantId } = this.props.router.params;

        appointmentId$.next(appointmentId);
        participantId$.next(participantId);

        this.subscriptions.push(
            room$.subscribe({
                next: ({ room, roomInternalStatus }) => {
                    this.setState({ room, roomInternalStatus });
                },
            }),
        );
        this.subscriptions.push(
            conversation$.subscribe({
                next: (conversation) => {
                    this.setState({ conversation: conversation });
                },
            }),
        );
        this.subscriptions.push(
            conversationSid$.subscribe({
                next: (sid) => {
                    this.setState({ conversationSid: sid });
                },
            }),
        );
        if (token && appointmentDetails) {
            this.connectToRoom(token, appointmentId, appointmentDetails);
            this.getConversationsData(participantId);
        }
        if (appointmentDetails?.conversationSid) {
            conversationSid$.next(appointmentDetails.conversationSid);
        }
        if (isMobileAgent) {
            window.removeEventListener('pagehide', () => {
                this.setState({ showCallEnded: false });
                this.leave;
            });
        }
        window.addEventListener('beforeunload', () => {
            this.setState({ showCallEnded: false });
            this.leave;
        });

        this.intervalId = setInterval(() => {
            if (this.state.room == null && this.state.roomInternalStatus != internalRoomStatusesMap.CALL_ENDED) {
                this.setState({ showCallEnded: false });
                window.location.reload();
            }
            clearInterval(this.intervalId);
        }, 30000);
    }

    componentWillUnmount() {
        const { room } = this.state;
        if (isMobileAgent) {
            window.removeEventListener('pagehide', this.leave);
        }
        window.removeEventListener('beforeunload', this.leave);
        room && room.off('disconnected', this.handleDisconnect);
        clearInterval(this.intervalId);
        super.componentWillUnmount();
    }

    componentDidUpdate(prevProps, prevState) {
        const { room } = this.state;
        const { token: prevToken } = prevProps;
        const { token, appointmentDetails } = this.props;

        let id = null;
        if (
            prevToken !== token &&
            token &&
            appointmentDetails &&
            !this.state.memberIsConnected &&
            !(this.state.memberIsConnected === true) &&
            appointmentStatusMap[appointmentDetails.status] != appointmentStatuses.MissedAppointment
        ) {
            this.connectToRoom(token, id, appointmentDetails);
            this.getConversationsData(this.props.router.params.participantId);
            if (appointmentDetails?.conversationSid) {
                conversationSid$.next(appointmentDetails.conversationSid);
            }
        }
        if (prevState.room !== room) {
            if (prevState.room) {
                prevState.room.off('disconnected', this.handleDisconnect);
            }
            if (room) {
                room.on('disconnected', this.handleDisconnect);
            }
        }
        if (prevProps.appointmentDetails?.memberLanguage !== this.props.appointmentDetails?.memberLanguage) {
            this.props.i18n.changeLanguage(getLangCode(this.props.appointmentDetails?.memberLanguage));
        }
    }

    getConversationsData = (participantId) => {
        const appointment = this.props.appointmentDetails;
        const optionalAttendees = this.props.appointmentDetails.optionalAttendees;

        let identity = null;
        let name = null;

        if (participantId === appointment.memberConnectionId) {
            identity = appointment.memberId;
            name = appointment.memberFullName;
        } else if (optionalAttendees !== null && optionalAttendees !== undefined && optionalAttendees.length > 0) {
            const index = optionalAttendees.findIndex((x) => x.connectionId === participantId);
            if (index > 0) {
                identity = optionalAttendees[index].relationshipDescription + generateUniqueId();
                name = optionalAttendees[index].fullName;
            }
        }

        if (identity !== null) {
            this.setState({ participantIdentity: identity });
            this.subscriptions.push(
                conversationService.getToken$(identity).subscribe({
                    next: (response) => {
                        this.setState({ conversationsToken: response.response.token });
                    },
                }),
            );
        } else {
            this.setState({ participantIdentity: generateUniqueId() });
        }

        if (name !== null) {
            this.setState({ participantName: name });
        } else {
            this.setState({ participantName: 'Unknown' });
        }
    };

    handleDisconnect = (room) => {
        room.localParticipant.tracks.forEach((publication) => {
            if (publication.track.kind === 'data') {
                return;
            }
            publication.track && publication.track.stop();
            const attachedElements = publication.track.detach();
            attachedElements.forEach((element) => element.remove());
        });
    };

    leave = () => {
        const { room, conversation } = this.state;

        if (room) {
            room.disconnect();
        }
        if (conversation) {
            conversation.leave();
        }

        room$.next({ room: null, roomInternalStatus: internalRoomStatusesMap.CALL_ENDED });

        conversation$.next(null);

        window.ReactNativeWebView && window.ReactNativeWebView.postMessage('CALL_ENDED');
    };

    isAttendee = () => {
        const { appointmentDetails } = this.props;
        const participantId = this.props.router.params.participantId;
        if (!appointmentDetails) {
            return false;
        }
        return appointmentDetails.optionalAttendees.some((attendee) => attendee.connectionId === participantId);
    };

    getAttendeeFullName = () => {
        const { appointmentDetails } = this.props;
        const participantId = this.props.router.params.participantId;
        if (!appointmentDetails) {
            return false;
        }
        const attendee = appointmentDetails.optionalAttendees.find(
            (attendee) => attendee.connectionId === participantId,
        );
        return attendee.fullName;
    };

    getMessage = (appointmentDetails) => {
        switch (appointmentStatusMap[appointmentDetails.status]) {
            case appointmentStatuses.NotStarted:
            case appointmentStatuses.Started:
            case appointmentStatuses.Confirmed: {
                if (!this.state.permissionDenied) {
                    return <>{this.t('joinedMeetingRoom', this.props.appointmentDetails.memberFirstName)}</>;
                } else {
                    return <>{this.t('declicnedMicrophoneAccess')}</>;
                }
            }
            case appointmentStatuses.CanceledByMember:
            case appointmentStatuses.CanceledByProvider: {
                return <>{`This virtual appointment has been cancelled.`}</>;
            }
            case appointmentStatuses.NoShow:
            case appointmentStatuses.Invalid:
            case appointmentStatuses.Rescheduled:
            case appointmentStatuses.Completed: {
                return <>{`This virtual appointment has been completed.`}</>;
            }
            case appointmentStatuses.MissedAppointment: {
                if (appointmentDetails.isVCC) {
                    return (
                        <>
                            {`Your appointment has been revised. For assistance, please call your 
                            Alignment Care Team at 1-833-402-5803.`}
                        </>
                    );
                }
                if (appointmentDetails.isJSA) {
                    return (
                        <>
                            {`Your appointment has been revised. For assistance, please call your 
                            Alignment Care Team at 1-844-215-2443.`}
                        </>
                    );
                }
                if (appointmentDetails.isCAW) {
                    return (
                        <>
                            {`Your appointment has been revised. For assistance, please call your 
                            Alignment Care Team at 1-833-413-9745.`}
                        </>
                    );
                } else {
                    return (
                        <>
                            {`I'm sorry, I could not find the details of your virtual appointment. For
                            assistance, please call your Virtual Care Team at 1-855-AVA-VIRTUAL, or
                            1-855-282-8478.`}
                        </>
                    );
                }
            }
        }
    };

    render() {
        const { room, roomInternalStatus } = this.state;
        const { appointmentDetails } = this.props;
        return (
            <>
                <Modal
                    show={this.state.shouldShowModal}
                    onHide={() => this.setState({ shouldShowModal: false, modalText: '' })}
                >
                    <Modal.Body>{this.state.modalText}</Modal.Body>
                </Modal>
                <div className={styles['virtual-room-bg']} />
                <section ref={(el) => (this.virtualRoomInstance = el)} className={`${styles['virtual-room']}`}>
                    {!(
                        room && appointmentStatusMap[appointmentDetails.status] != appointmentStatuses.MissedAppointment
                    ) &&
                        ![internalRoomStatusesMap.CALL_ENDED, internalRoomStatusesMap.DISCONNECTED_BY_HOST].includes(
                            roomInternalStatus,
                        ) &&
                        this.props.appointmentDetails && (
                            <>
                                <div className={styles['absense-container']}>
                                    {this.t('welcome', {
                                        memberName: this.isAttendee()
                                            ? this.getAttendeeFullName()
                                            : appointmentDetails.memberFirstName,
                                    })}
                                    <br />
                                    {this.getMessage(appointmentDetails)}
                                </div>
                                <div className={styles.actions}>
                                    <button
                                        type='button'
                                        className={`${styles.inactive} ${styles.action}`}
                                        onClick={this.leave}
                                    >
                                        <FontAwesomeIcon icon={faPhone} />
                                    </button>
                                </div>
                            </>
                        )}
                    {!room && roomInternalStatus === internalRoomStatusesMap.DISCONNECTED_BY_HOST && (
                        <div className={styles['absense-container']}>{this.t('disconnectedFromCall')}</div>
                    )}
                    {!room && roomInternalStatus === internalRoomStatusesMap.CALL_ENDED && this.state.showCallEnded && (
                        <div className={styles['absense-container']}>{this.t('callEnded')}</div>
                    )}
                    {room &&
                        appointmentStatusMap[appointmentDetails.status] != appointmentStatuses.MissedAppointment && (
                            <RoomBody
                                appointment={appointmentDetails}
                                onLeave={this.leave}
                                conversationsToken={this.state.conversationsToken}
                                conversationSid={this.state.conversationSid}
                                participantName={this.state.participantName}
                                participantIdentity={this.state.participantIdentity}
                                featureFlags={this.props.featureFlags}
                            />
                        )}
                </section>
            </>
        );
    }
}

export default withTranslation()(withRouter(withRoomDetails(withRoomToken(VirtualRoom))));
