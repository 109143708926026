import React from 'react';
import { VbidParams } from './VbidPage.metadata';

export interface UnauthenticatedVbidFormProps {
    memberData: VbidParams;
    onFormSubmitted: () => void;
}

export interface VbidForm {
    memberId: string;
    answer1: string | '';
    answer2: LivingSituation[];
    answer3: string[];
    answer4: Answer4;
    answer5: TransportationDifficulty[];
    wontAnswer1: boolean;
    wontAnswer2: boolean;
    wontAnswer4: boolean;
    wontAnswer5: boolean;
    dirty1: boolean;
    dirty2: boolean;
    dirty3: boolean;
    dirty4: boolean;
    dirty5: boolean;
}

export interface SubmitResponse {
    success: boolean;
    message: string;
    caseNo: string;
}

export const DefaultVbidForm = (memberId: string): VbidForm => ({
    memberId: memberId,
    answer1: '',
    answer2: [],
    answer3: [],
    answer4: { selected: [], other: '' },
    answer5: [],
    wontAnswer1: false,
    wontAnswer2: false,
    wontAnswer4: false,
    wontAnswer5: false,
    dirty1: false,
    dirty2: false,
    dirty3: false,
    dirty4: false,
    dirty5: false,
});

type ExtractType<T, U extends T> = T extends U ? T : never;
export type HtmlInputHandler = (event: React.ChangeEvent<HTMLInputElement>) => void;
export type AnswerField = ExtractType<keyof VbidForm, 'answer1' | 'answer2' | 'answer3' | 'answer4' | 'answer5'>;
export type DirtyField = ExtractType<keyof VbidForm, 'dirty1' | 'dirty2' | 'dirty3' | 'dirty4' | 'dirty5'>;
export type WontAnswer = ExtractType<keyof VbidForm, 'wontAnswer1' | 'wontAnswer2' | 'wontAnswer4' | 'wontAnswer5'>;
export type ResetValue = ExtractType<keyof VbidForm, 'answer1' | 'answer2' | 'answer4' | 'answer5'>;

export const LivingSituations = [
    'I have housing',
    'I do not have housing (staying with others, in a hotel, in a shelter, living outside on the street, on a beach, in a car, abandoned building, bus or train station, or in a park)',
] as const satisfies readonly string[];
export type LivingSituation = typeof LivingSituations[number];

export const FamilyResources = [
    'Food',
    'Utilities',
    'Phone',
    'Child Care',
    'Clothing',
    'Medicine or Any Health Care (Medical, Dental, Mental Health, Vision)',
    'Other (please write in)',
] as const satisfies readonly string[];
export type FamilyResource = typeof FamilyResources[number];
export const OtherFamilyResource = 'Other (please write in)' as FamilyResource;

interface OpenAnswer<tAnswer extends string, > {
    other: string,
    selected: tAnswer[],
}

export interface Answer4 extends OpenAnswer<FamilyResource> {
}

export const TransportationDifficulties = [
    'Yes, it has kept me from medical appointments or from getting my medications',
    'Yes, it has kept me from non-medical meetings, appointments, work, or getting things that I need',
    'No',
] as const satisfies readonly string[];
export type TransportationDifficulty = typeof TransportationDifficulties[number];
export const ResetDifficulty = 'No' as TransportationDifficulty;

const IsDirtyAndWithoutValue = <
    key extends AnswerField,
    dirtyKey extends DirtyField>(
    field: key,
    dirtyField: dirtyKey,
    isDefaultValue: (field: VbidForm[key]) => boolean): (form: VbidForm) => boolean => {
    return (form) => form[dirtyField] === true && isDefaultValue(form[field]);
};

const isEmptyArray = (answer: string[]): boolean => answer.length === 0;
export const IsDirty1 = IsDirtyAndWithoutValue('answer1', 'dirty1', (answer) => answer === '');
export const IsDirty2 = IsDirtyAndWithoutValue('answer2', 'dirty2', isEmptyArray);
export const IsDirty3 = IsDirtyAndWithoutValue('answer3', 'dirty3', isEmptyArray);
export const IsDirty4 = IsDirtyAndWithoutValue('answer4', 'dirty4', (answer) => {
    const didntSpecify = answer.selected.includes(OtherFamilyResource) && answer.other === '';
    return isEmptyArray(answer.selected) || didntSpecify;
});
export const IsDirty5 = IsDirtyAndWithoutValue('answer5', 'dirty5', isEmptyArray);

export const ValidNumberResponse = (number: number | any) => typeof number === 'number' || number > 0;
export const ValidStringArrayResponse = (array: string[]) => array.length > 0;
export const ValidOpenAnswer = <tAnswer extends OpenAnswer<any>>({ selected, other }: tAnswer) =>
    selected.includes(OtherFamilyResource)
        ? other.length > 0
        : true;

export const ValidateForm = (form: VbidForm): form is VbidForm => (
    (ValidNumberResponse(form.answer1) || form.wontAnswer1) &&
    (ValidStringArrayResponse(form.answer2) || form.wontAnswer2) &&
    (ValidStringArrayResponse(form.answer3)) &&
    ((ValidStringArrayResponse(form.answer4.selected) && ValidOpenAnswer(form.answer4)) || form.wontAnswer4) &&
    (ValidStringArrayResponse(form.answer5) || form.wontAnswer5)
);
