import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface Props {
    showErrorModal: boolean;
    text: string;
    handleCloseErrorModal?: () => void;
}

export const ErrorRequestModal = ({ showErrorModal, handleCloseErrorModal, text }: Props) => {
    return (
        <Modal centered show={showErrorModal} onHide={handleCloseErrorModal}>
            <Modal.Body>
                {text}
            </Modal.Body>
            {
                handleCloseErrorModal &&
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseErrorModal}>
                        Close
                    </Button>
                </Modal.Footer>
            }
        </Modal>
    );
};
