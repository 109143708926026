import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

interface AppConfig {
    instrumentationKey: string;
}

const config$ = new BehaviorSubject<AppConfig | null>(null);

export const configService = {
    setConfiguration: (config: AppConfig) => config$.next(config),
    configuration$: config$.pipe(filter((item) => item !== null)),
};
