const handleEnterKey = (handleFunc: any) => (e: any) => {
    if (e.key === 'Enter' && typeof handleFunc === 'function') {
        handleFunc(e);
    }
};

const handleEnterKeyToClick = (e: any) => {
    handleEnterKey(() => {
        const self = e.target;
        if (self.click && typeof self.click === 'function') {
            self.click(e);
        }
    })(e);
};

const focusNearestActiveInput = (targetRef: any) => {
    const inputFieldTagNames = ['INPUT', 'TEXTAREA', 'BUTTON', 'SELECT'];
    const querySelectorStr =
        'input:not(:disabled), textarea:not(:disabled), button:not(:disabled), select:not(:disabled)';
    try {
        if (inputFieldTagNames.includes(targetRef.current.tagName) && !targetRef.current.disabled) {
            targetRef.current.focus();
        } else {
            const foundInput = targetRef.current.querySelector(querySelectorStr);
            foundInput.focus();
        }
    } catch (e) {
        console.log('focusNearestActiveInput', e);
    }
};

function decodeBase64ToJson(encodedString: any) {
    try {
        const decodedString = atob(encodedString);
        return JSON.parse(decodedString);
    } catch {
        return false;
    }
}

function decodeBase64Json<T,>(encodedString: any): T | null {
    try {
        const decodedString = atob(encodedString);
        const result = JSON.parse(decodedString) as T;
        return result || null;
    } catch {
        return null;
    }
}

export { handleEnterKey, handleEnterKeyToClick, focusNearestActiveInput, decodeBase64ToJson, decodeBase64Json };
