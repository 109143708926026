import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import './Vbid.module.scss';
import { trackEvent } from '../../ApplicationInsights';
import { VbidRequestedAppModal } from './VbidRequestedAppModal';
import { ErrorRequestModal } from '../Common/ErrorRequestModal';
import unauthenticatedService from '../../services/unauthenticatedJsaService';
import { Loading } from '../Common/Loading';
import {
    DefaultVbidForm,
    FamilyResources,
    LivingSituations,
    WontAnswer,
    HtmlInputHandler,
    ResetValue,
    UnauthenticatedVbidFormProps,
    VbidForm,
    TransportationDifficulties,
    OtherFamilyResource,
    ResetDifficulty,
    ValidateForm,
    DirtyField,
    IsDirty1,
    IsDirty2,
    IsDirty3,
    IsDirty4,
    IsDirty5,
    SubmitResponse,
} from './UnauthenticatedVbidForm.metadata';
import { useUnmount } from '../../common/useUnmount';
import React from 'react';
import styles from './Vbid.module.scss';
import { TextField } from '@mui/material';
import { configService } from '../../services/configService';

export const UnauthenticatedVbidForm = ({ memberData, onFormSubmitted }: UnauthenticatedVbidFormProps) => {
    const unmountSub = useUnmount();

    const [form, setForm] = useState<VbidForm>(DefaultVbidForm(memberData.memberId));
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        unmountSub(
            configService.configuration$,
            (_) => trackEvent({ name: 'TelehealthVBIDFormOpened' }, { memberId: memberData.memberId }),
        );
    }, []);

    const handleOnChange = <k extends keyof VbidForm, v extends VbidForm[k]>(
        key: k,
        value: v,
    ) => setForm((prevState) => ({
        ...prevState,
        [key]: value,
    }));

    const setDirtyField = <k extends DirtyField>(dirtyField: k, value: boolean) => setForm((prevState) => ({
        ...prevState,
        [dirtyField]: value,
    }));

    const answerMap = <tAnswer extends string, >(
        htmlKey: string,
        className: string,
        selectedItems: tAnswer[],
        multipleAllowed: boolean,
        setNewValues: (newValues: tAnswer[], toggledValue: tAnswer, checked: boolean) => void) =>
        (answer: tAnswer, i: number) => {
            return <FormControlLabel
                className={'align-items-start ' + className}
                key={`${htmlKey}${i}`}
                control={
                    <Checkbox
                        checked={selectedItems.includes(answer)}
                        onChange={handleAnswerToggled(selectedItems, multipleAllowed, setNewValues)}
                        name={answer}
                    />
                }
                label={answer}
                labelPlacement={'end'}
            />;
        };

    const handleAnswerToggled = <tAnswer, >(
        selectedAnswers: tAnswer[],
        multipleAllowed: boolean,
        setNewValues: (newValues: tAnswer[], toggledValue: tAnswer, checked: boolean) => void): HtmlInputHandler =>
        ({ target }) => {
            const toggledItem = target.name as tAnswer;
            const toggledItemExists = selectedAnswers.includes(toggledItem);
            const newValues = !multipleAllowed
                ? [...target.checked ? [toggledItem] : []]
                : toggledItemExists
                    ? selectedAnswers.filter((existingItem) => existingItem != toggledItem)
                    : [...selectedAnswers, toggledItem];
            setNewValues(newValues, toggledItem, target.checked);
        };

    const NotToAnswer = <
        notToAnswerKey extends WontAnswer,
        dirtyKey extends DirtyField,
        resetKey extends ResetValue,
        resetType extends VbidForm[resetKey]>(
        notToAnswerField: notToAnswerKey,
        dirtyField: dirtyKey,
        resetField: resetKey,
        resetValue: resetType) => {
        const onChange: HtmlInputHandler = (({ target }) => {
            target.checked && handleOnChange(resetField, resetValue);
            handleOnChange(notToAnswerField, target.checked);
            setDirtyField(dirtyField, !target.checked);
        });

        return (
            <FormControl className="col-sm-12 ml-3" component="fieldset" variant="standard">
                <FormGroup className={'row ' + styles['form-question']}>
                    <FormControlLabel
                        key={`not-to-answer-form-control-label${notToAnswerField}`}
                        className={'align-items-start col-sm-12'}
                        control={
                            <Checkbox
                                inputProps={{
                                    'aria-label': 'controlled',
                                }}
                                value={form[notToAnswerField]}
                                checked={form[notToAnswerField]}
                                onChange={onChange}
                                name="notToAnswer-checkbox" />
                        }
                        label="I choose not to answer this question"
                    />
                </FormGroup>
            </FormControl>
        );
    };

    const onAnswer1Changed: HtmlInputHandler = ({ target }) => {
        target.value && handleOnChange('wontAnswer1', false);
        const newValue = target.value && parseInt(target.value) || '';
        handleOnChange('answer1', newValue > 100 ? '' : newValue.toString());
        setDirtyField('dirty1', true);
    };

    const answer2Map = answerMap(
        'question2',
        'col-sm-12',
        form.answer2,
        false,
        (newSelection, _, __) => {
            setDirtyField('dirty2', true);
            handleOnChange('wontAnswer2', false);
            handleOnChange('answer2', newSelection);
        },
    );

    const answer3Map = answerMap(
        'question3',
        'col-sm-3',
        form.answer3,
        false,
        (newSelection, _, __) => {
            setDirtyField('dirty3', true);
            handleOnChange('answer3', newSelection);
        },
    );

    const answer4Map = answerMap(
        'question4',
        'col-sm-5',
        form.answer4.selected,
        true,
        (newSelection, toggledItem, checked) => {
            setDirtyField('dirty4', true);
            handleOnChange('wontAnswer4', false);
            handleOnChange('answer4', ({
                ...form.answer4,
                selected: newSelection,
                ...(toggledItem === OtherFamilyResource && !checked ? { other: '' } : {}),
            }));
        },
    );

    const SpecifyOther = () => {
        const showSpecify = form.answer4.selected.includes(OtherFamilyResource);
        const onSpecifyChanged: HtmlInputHandler = (e) => handleOnChange('answer4', ({
            ...form.answer4,
            other: e.target.value,
        }));

        return showSpecify &&
            (<TextField
                className="col-sm-12 mt-2 ml-3"
                id="outlined-controlled"
                value={form.answer4.other}
                variant="standard"
                placeholder="Type your answer"
                multiline
                maxRows={8}
                onChange={onSpecifyChanged}
            />);
    };

    const answer5Map = answerMap(
        'question5',
        'col-sm-12',
        form.answer5,
        true,
        (newSelection, toggledItem, checked) => {
            const updatedSelection = toggledItem === ResetDifficulty && checked
                ? [ResetDifficulty]
                : newSelection.filter((selectedAnswer) => selectedAnswer !== ResetDifficulty);
            setDirtyField('dirty5', true);
            handleOnChange('answer5', updatedSelection);
            handleOnChange('wontAnswer5', false);
        },
    );

    const invalidForm = !ValidateForm(form);

    const onSubmitForm = () => {
        setLoading(true);
        const responseHandler = (_: SubmitResponse) => {
            setLoading(false);
            setShowSuccessModal(true);
            onFormSubmitted();
        };
        const onError = (_: any) => {
            setLoading(false);
            setShowErrorModal(true);
        };
        unmountSub(unauthenticatedService.createVbidRequest$(form), {
            next: responseHandler,
            error: onError,
        });
    };

    const handleSubmittedVbidRequest = () => {
        setForm(DefaultVbidForm(memberData.memberId));
        window.location.href = 'https://members.alignmenthealthplan.com/';
        setShowSuccessModal(false);
    };

    return (
        <>
            <div className={'row ' + styles['vbid-form']}>
                <p className="col-sm-12 col-md-9">
                    1. How many family members, including yourself, do you currently live with?
                </p>
                <TextField
                    className="col-sm-12 col-md-3 mt-4"
                    error={IsDirty1(form)}
                    helperText={IsDirty1(form) ? 'Field required' : ''}
                    id="outlined-controlled"
                    onChange={onAnswer1Changed}
                    placeholder="Type your answer"
                    value={form.answer1}
                    variant="standard"
                />
                {
                    NotToAnswer('wontAnswer1', 'dirty1', 'answer1', '')
                }
            </div>
            <div className={'row ' + styles['vbid-form']}>
                <p className="col-sm-12">
                    2. What is your living situation today?
                </p>
                <FormControl
                    className="col-sm-12 ml-3"
                    error={IsDirty2(form)}
                    component="fieldset"
                    variant="standard"
                >
                    <FormLabel component="legend">Check all that apply</FormLabel>
                    <FormGroup className={'row ' + styles['form-question']}>
                        {LivingSituations.map(answer2Map)}
                    </FormGroup>
                </FormControl>
                {
                    NotToAnswer('wontAnswer2', 'dirty2', 'answer2', [])
                }
            </div>
            <div className={'row ' + styles['vbid-form']}>
                <p className="col-sm-12">
                    3. Are you worried about losing your housing?
                </p>
                <FormControl
                    className="col-sm-12 ml-3"
                    error={IsDirty3(form)}
                    component="fieldset"
                    variant="standard"
                >
                    <FormLabel component="legend">Select one</FormLabel>
                    <FormGroup className={'row ' + styles['form-question']}>
                        {['Yes', 'No'].map(answer3Map)}
                    </FormGroup>
                </FormControl>
            </div>
            <div className={'row ' + styles['vbid-form']}>
                <p className="col-sm-12">
                    4. In the past year, have you or any family members you live with been unable to get any of the
                    following when it was really needed?
                </p>
                <FormControl
                    className="col-sm-12 ml-3"
                    error={IsDirty4(form)}
                    component="fieldset"
                    variant="standard"
                >
                    <FormLabel component="legend">Check all that apply</FormLabel>
                    <FormGroup className={'row ' + styles['form-question']}>
                        {FamilyResources.map(answer4Map)}
                    </FormGroup>
                </FormControl>
                {
                    SpecifyOther()
                }
                {
                    NotToAnswer('wontAnswer4', 'dirty4', 'answer4', ({ selected: [], other: '' }))
                }
            </div>
            <div className={'row ' + styles['vbid-form']}>
                <p className="col-sm-12">
                    5. Has lack of transportation kept you from medical appointments, meetings, work, or from
                    getting
                    things needed for daily living?
                </p>
                <FormControl
                    className="col-sm-12 ml-3"
                    error={IsDirty5(form)}
                    component="fieldset"
                    variant="standard"
                >
                    <FormLabel component="legend">Check all that apply</FormLabel>
                    <FormGroup className={'row ' + styles['form-question']}>
                        {TransportationDifficulties.map(answer5Map)}
                    </FormGroup>
                </FormControl>
                {
                    NotToAnswer('wontAnswer5', 'dirty5', 'answer5', [])
                }
                <div className="col-lg text-center mt-5">
                    <Button
                        className="button"
                        onClick={onSubmitForm}
                        variant="primary"
                        size="lg"
                        disabled={invalidForm}
                    >
                        Submit
                    </Button>
                </div>
                <VbidRequestedAppModal
                    show={showSuccessModal}
                    onHideModal={handleSubmittedVbidRequest}
                />
                <ErrorRequestModal
                    showErrorModal={showErrorModal}
                    handleCloseErrorModal={() => setShowErrorModal(false)}
                    text={`We're sorry, there was an issue while submitting your answers. Please try again
                later.`}
                />
                {loading && <Loading />}
            </div>
        </>
    );
};