import { createTheme } from '@mui/material';

export const vbidTheme = createTheme({
    typography: {
        fontFamily: [
            'Raleway',
        ].join(','),
        fontSize: 16,
    },
});

export type VbidKey = {
    vbidKey: string;
}

export interface VbidParams {
    memberId: string;
    memberName: string;
}

export interface FormStatus {
    completed: boolean;
    completedOn: Date | null;
}

export const defaultFormStatus: FormStatus = {
    completed: false,
    completedOn: null,
};