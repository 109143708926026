import React from 'react';
import { handleEnterKeyToClick } from '../Common/accessibilityHelpers';
import {  Col, Row } from 'reactstrap';
import { Modal} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import styles from './Vbid.module.scss';

interface Props {
    show: boolean;
    onHideModal: () => void;
}
export const VbidRequestedAppModal = ({ show, onHideModal }: Props) => {
    return (
        <Modal centered show={show} onHide={onHideModal}>
            <div className={styles['success-alert']}>
                <div className={styles['success-modal-container']}>
                    <h3 style={{ display: 'inline-block' }}>Request Submitted</h3>
                </div>
                <div className="text-center">
                    <p>
                        Thank you! Your request has been submitted.
                    </p>
                    <p>
                        If have questions, please call us at 844-215-2443 (TTY: 711), Mon - Fri, 8 AM - 6 PM
                        PST (excluding holidays).
                    </p>
                </div>
                <Row>
                    <Col>
                        <div className="d-flex justify-content-center">
                            <button
                                className={styles['modal-btn']}
                                onClick={onHideModal}
                                onKeyUp={handleEnterKeyToClick}
                                tabIndex={0}
                            >
                                OK
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};
