import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';

import './custom.scss';
import VirtualRoom from './components/VirtualRoom/VirtualRoom';
import TelehealthTest from './components/TelehealthTest/TelehealthTest';
import ProviderVirtualRoom from './components/ProviderVirtualRoom/ProviderVirtualRoom';
import Feature from './hoc/Feature';
import Admin from './components/ConversationRoom/Admin';
import JSAPage from './components/JSAFlow/JSAPage';
import VBIDPage from './components/VBIDFlow/VbidPage';

export default function App() {
    const [featureFlags, setFeatureFlags] = useState(null);

    useEffect(() => {
        fetch('api/config/featureFlags', {
            method: 'GET',
        })
            .then((response) => response.json())
            .then((data) => {
                setFeatureFlags(data.flags);
            })
            .catch((err) => {
                console.log(err);
                setFeatureFlags([]);
            });
    }, []);
    return (
        <Routes>
            <Route path='/jsa-request-flow/:jsakey' element={<JSAPage />} />
            <Route path='/vbid-flow/:vbidKey' element={<VBIDPage />} />
            <Route path='/pcp/:appointmentId/:participantId' element={<ProviderVirtualRoom />} />
            <Route path='/:appointmentId/:participantId' element={<VirtualRoom featureFlags={featureFlags} />} />
            <Route path='/TelehealthTest' element={<TelehealthTest featureFlags={featureFlags} />} />
            <Route
                path='/admin'
                element={
                    <Feature name='ConversationsAdmin' featureFlags={featureFlags}>
                        <Admin />
                    </Feature>
                }
            />
        </Routes>
    );
}
