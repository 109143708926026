import React from 'react';
import { Container } from 'reactstrap';
import './styles.scss';
import Footer from '../Common/Footer';
import { UnauthenticatedHeader } from '../Common/UnauthenticatedHeader';
import { UnauthenticatedJSAForm } from './UnauthenticatedJSAForm';
import withRouter from '../../hoc/withRouter';
import { decodeBase64ToJson } from '../Common/accessibilityHelpers';
import { ErrorRequestModal } from '../Common/ErrorRequestModal';

const JSAPage = (props: any) => {
    const { jsakey } = props.router.params;
    const memberInfo = decodeBase64ToJson(jsakey);
    const { memberId, memberName, rewardAmount } = memberInfo;
    return (
        <div className="jsa-flow">
            <UnauthenticatedHeader />
            <br />
            <br />
            <Container id="main-container" role="main" aria-label="main content area">
                <h1>JSA Appointment Request</h1>
                <br />
                <br />
                <p style={{ fontFamily: 'Raleway' }}>
                    {' '}
                    Dear <strong style={{ fontFamily: 'Raleway' }}> {memberName}, </strong>{' '}
                </p>
                <p style={{ textAlign: 'justify', fontFamily: 'Raleway' }}>
                    {`Did you know you are eligible to earn a $${rewardAmount} Healthy Reward for scheduling your
                    no-cost health checkup? With Alignment’s Jump Start Assessment, a clinician will
                    review your health care goals, help remove any barriers in your care, and
                    support care coordination where it’s convenient for you—in your home or through
                    a telehealth visit. This service is offered in addition to your routine primary
                    care visits. Simply select a date and time that is convenient for you below, or
                    call our scheduling team at 1-844-215-2443 (TTY: 711), 8 am to 6 pm, Monday
                    through Friday.`}
                </p>
                <UnauthenticatedJSAForm memberId={memberId} />

                <br />
            </Container>
            <ErrorRequestModal
                showErrorModal={!memberInfo}
                handleCloseErrorModal={() => {}}
                text={`There is an error with your token`}
            />
            <Footer />
        </div>
    );
};

export default withRouter(JSAPage);
