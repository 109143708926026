import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';

const unauthenticatedJsaAppointmentService = {
    createJsaRequest$: (requestData) =>
        ajax({
            method: 'POST',
            url: `api/JSA`,
            body: requestData,
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(map((response) => response.response.appointmentId)),
    createVbidRequest$: (requestData) =>
        ajax({
            method: 'POST',
            url: `api/VBID`,
            body: requestData,
            headers: {
                'Content-Type': 'application/json',
            },
        }).pipe(map((response) => response.response)),
};

export default unauthenticatedJsaAppointmentService;
