import React, { useState } from 'react';
import { Container } from 'reactstrap';
import './Vbid.module.scss';
import Footer from '../Common/Footer';
import { UnauthenticatedHeader } from '../Common/UnauthenticatedHeader';
import { UnauthenticatedVbidForm } from './UnauthenticatedVbidForm';
import withRouter from '../../hoc/withRouter';
import { decodeBase64Json, decodeBase64ToJson } from '../Common/accessibilityHelpers';
import { ErrorRequestModal } from '../Common/ErrorRequestModal';
import styles from './Vbid.module.scss';
import { ThemeProvider } from '@mui/material';
import { defaultFormStatus, FormStatus, VbidKey, VbidParams, vbidTheme } from './VbidPage.metadata';
import { useParams } from 'react-router-dom';

const VbidPage = (props: any) => {
    const [formStatus, setFormStatus] = useState<FormStatus>(defaultFormStatus);
    const { vbidKey } = useParams<VbidKey>();
    const memberInfo = decodeBase64ToJson(vbidKey) as VbidParams;

    const onFormSubmitted = () => {
        setFormStatus({ completed: true, completedOn: new Date() });
    };

    const completedDate = <>
        &nbsp;Completed: <span>{formStatus.completedOn?.toDateString()}</span>
    </>;

    const content = (data: VbidParams) => (
        <div className="container">
            <div className={styles['header']}>
                <h1>Annual Health and Wellness Assessment</h1>
                <h2 className="mb-5">Health and Wellness or Prevention Information</h2>
                <div className="row">
                    <p className="col-sm-12 ">
                        Member Name: <span>{memberInfo?.memberName}</span>
                    </p>
                    <p className="col-sm-12">
                        Date{formStatus.completed ? completedDate : <span>{`: ${new Date().toDateString()}`}</span>}
                    </p>
                    <p className="mt-3 col-lg">
                        Instructions: <span>Please read each question and mark the box for your answer. Thank you.</span>
                    </p>
                </div>
            </div>
            <UnauthenticatedVbidForm
                memberData={data}
                onFormSubmitted={onFormSubmitted}
            />
            <br />
        </div>
    );

    return (
        <ThemeProvider theme={vbidTheme}>
            <div className="vbid-flow">
                <UnauthenticatedHeader />
                <Container id="main-container" role="main" aria-label="main content area">
                    {memberInfo && content(memberInfo)}
                </Container>
                <ErrorRequestModal
                    showErrorModal={!memberInfo}
                    text="There is an error with your token"
                />
                {memberInfo && <Footer />}
            </div>
        </ThemeProvider>
    );
};

export default withRouter(VbidPage);
